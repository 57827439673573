<template>
  <div v-if="currentOffboardingStep > 1 && currentOffboardingStep < 7">
    <v-stepper
      v-if="$vuetify.breakpoint.smAndUp"
      :alt-label="$vuetify.breakpoint.sm"
      readonly
      class="transparent elevation-0"
      :value="currentStep"
    >
      <v-stepper-header>
        <v-stepper-step step="1" :complete="currentStep > 1" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 1 ? 'font-weight-medium grey--text' : ''
            } ${currentStep > 1 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.userSelection") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="2" :complete="currentStep > 2" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 2 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 2 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.inventory") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="3" :complete="currentStep > 3" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 3 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 3 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.transferEmails") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="4" :complete="currentStep > 4" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 4 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 4 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.transferDrive") }}
          </div>
        </v-stepper-step>

        <v-stepper-step step="5" :complete="currentStep > 5" color="accent">
          <div
            :class="`d-flex text-center text-wrap normal-line-height ${
              currentStep === 5 ? 'font-weight-bold grey--text' : ''
            } ${currentStep > 5 ? 'font-weight-medium grey--text' : ''}`"
          >
            {{ $t("common.licences") }}
          </div>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <v-progress-linear
      background-color="grey lighten-2"
      color="accent"
      :value="progress"
      height="7"
      :class="`rounded-t-xl`"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "OffboardingProgressBar",

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["currentOffboardingStep", "offboardingStepsNumber"]),

    progress() {
      return parseInt((this.currentStep * 100) / this.offboardingStepsNumber);
    },

    currentStep() {
      return parseInt(this.currentOffboardingStep) - 1;
    },
  },
};
</script>
