<template>
  <v-container style="max-width: 1160px" class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="pa-4 elevation-0 overflow-y-auto"
      height="calc(89vh - 80px)"
      width="100%"
    >
      <Alert
        class="mt-2"
        dense
        color="warning"
        v-if="!offboardingUser.is_gmail_enabled"
      >
        {{ $t("common.inactiveUserInWorkspaceAlert") }}
      </Alert>
      <Alert class="mt-2" dense color="warning" v-if="hasScopePending">
        <v-row class="ma-0 pa-0" no-gutters align="center">
          <v-col lg="9" sm="12" md="9" class="pa-0 grow">
            <span v-html="$t('common.hasDriveScopePending')"> </span>
          </v-col>

          <v-col lg="3" sm="12" md="3" class="pa-0 shrink d-flex justify-end">
            <v-btn
              text
              small
              color="secondary lighten-2"
              href="https://conectasuite.com/perguntas-frequentes/como-permitir-acesso-a-novos-escopos-no-painel-do-google-administrativo-2/"
              target="_blank"
              class="mr-2 text-body-2 font-weight-medium"
            >
              {{ $t("action.knowMore") }}
            </v-btn>
            <v-btn
              v-if="isAdmin"
              text
              small
              outlined
              color="warning"
              :href="MARKETPLACE_APP_URL"
              target="_blank"
              class="ml-auto text-body-2 font-weight-medium"
            >
              {{ $t("action.acceptScopes") }}
            </v-btn>
          </v-col>
        </v-row>
      </Alert>
      <!-- ALERTA PARA TRANSFERÊNCIA ROLANDO -->
      <Alert
        v-if="hasProcessRunning && offboardingUser.is_gmail_enabled"
        :prominent="false"
        color="info"
        dense
      >
        {{
          $t("offboardingUser.hasTransferenceRunningAlert", {
            email: offboardingUser.email,
          })
        }}
      </Alert>
      <!-- ALERTA DE GRUPOS DO USUÀRIO -->
      <Alert
        class="mt-2"
        dense
        color="warning"
        v-if="!groups || (!groups.length && !unavailablePlan && loading)"
      >
        {{ $t("common.userHasNoGroups") }}
      </Alert>
      <v-card-title class="pl-2 mt-6 font-weight-bold">
        {{ $t("offboardingUser.transferDriveTitle") }}
      </v-card-title>
      <v-card-subtitle class="pl-2">{{
        $t("offboardingUser.transferDriveSubtitle")
      }}</v-card-subtitle>

      <v-row class="mt-6">
        <v-col
          cols="3"
          class="mt-1 pa-0 mr-5 ml-5 d-flex flex-column justify-center align-center"
          style="max-width: 40px; max-height: 40px"
        >
          <v-img :src="driveIcon" alt="Drive icon"></v-img>
        </v-col>
        <v-col cols="9" class="pa-0 ml-5">
          <span class="font-weight-medium text--lighten-2"
            >{{ $t("offboardingUser.transferArquives") }}:</span
          >
          <v-card-subtitle class="pa-0">{{
            $t("offboardingUser.selectGroupToTransfer")
          }}</v-card-subtitle>
          <v-autocomplete
            v-model="group"
            :items="groups"
            item-text="email"
            item-value="id_google"
            return-object
            :label="$t('driveMigration.start.selectGroup')"
            clearable
            open-on-clear
            outlined
            style="max-width: 400px"
            class="mt-4"
            color="primary"
            :disabled="
              loading ||
              !offboardingUser ||
              hasScopePending ||
              unavailablePlan ||
              !!errorMessageKey ||
              hasProcessRunning
            "
            :loading="loading"
          />
        </v-col>
      </v-row>

      <div v-if="unavailablePlan" class="px-0 mx-auto d-flex justify-center">
        <v-card
          max-width="600"
          tile
          elevation="0"
          class="d-flex flex-column align-center justify-center"
        >
          <v-card-title>
            <v-icon left color="accentSuite">mdi-crown</v-icon>
            <span> {{ $t("common.upsellTitle") }} </span>
          </v-card-title>

          <v-card-text class="font-weight-medium text-center pb-4 mb-6">
            {{ $t("driveMigration.upsellInvitation") }}
          </v-card-text>
          <v-btn
            color="accent"
            class="text-none font-weight-medium text-body-2 white--text"
            elevation="0"
            @click="goToSelfCheckout"
          >
            {{ $t("common.checkPlans") }}
          </v-btn>
        </v-card>
      </div>

      <div
        v-if="externalMembers.length"
        class="px-0 mx-auto d-flex justify-center"
        style="width: 700px"
      >
        <v-col class="mx-0">
          <v-card flat>
            <Alert color="warning">
              <span
                >{{ $t("driveMigration.start.hasExternalMember") }}
                <TooltipIcon
                  color="primarySuite"
                  :label="$t('driveMigration.start.tooltipLabel')"
                  size="20"
                  icon="mdi-information"
                  max-width="500"
              /></span>

              <v-virtual-scroll
                :height="virtualScrollHeight"
                item-height="25"
                :items="externalMembers"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn depressed small>
                        <v-icon color="orange darken-4" left>
                          mdi-alert
                        </v-icon>
                        {{ $t("common.external") }}
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </Alert>
          </v-card>
        </v-col>
      </div>
    </v-card>

    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3 justify-space-between"
      width="100%"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="backStep"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8 mr-4"
        @click="$emit('next')"
        :disabled="loading"
      >
        {{ $t("action.skip") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="startTransfer()"
        :loading="loading"
        :disabled="
          !group ||
          !offboardingUser ||
          !offboardingUser.is_gmail_enabled ||
          hasScopePending ||
          !!errorMessageKey ||
          unavailablePlan ||
          hasProcessRunning
        "
      >
        {{ $t("action.transferAndNext") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  INCREMENTAL_SCOPES,
  MARKETPLACE_APP_URL,
} from "@/helpers/variables/scopes";
import { DISCOVERY_DOCS } from "@/helpers/variables/discoveryDocs";
import { getAnalytics, logEvent } from "firebase/analytics";
export default {
  name: "TransferDrive",
  components: {},
  data() {
    return {
      MARKETPLACE_APP_URL,
      loading: false,
      migrationsInQueue: [],
      showMigrationsInQueue: false,
      errorMessageKey: "",
      groups: [],
      group: "",
      groupScope: INCREMENTAL_SCOPES.ADMIN_DIRECTORY_GROUP,
      driveScope: INCREMENTAL_SCOPES.ADMIN_DRIVE,
      hasScopePending: false,
      driveIcon: require("@/assets/appslogos/icons/icon-google-drive.png"),
      unavailablePlan: false,
      externalMembers: [],
    };
  },
  computed: {
    ...mapGetters([
      "userOffboardSelected",
      "userGroups",
      "scopeCode",
      "isAdmin",
      "allDriveProcesses",
      "hasConectaControl",
      "hasProcessRunning",
      "company",
      "currentUser",
    ]),

    offboardingUser() {
      const user = JSON.parse(localStorage.getItem("offboardUser"));
      if (!this.userOffboardSelected && user) {
        this.setUserOffboardSelected(user);
      }
      return user || this.userOffboardSelected;
    },

    headers() {
      return [
        {
          value: "email",
          text: this.$t("common.email"),
          width: 180,
          sortable: false,
        },
        {
          value: "migrations_in_queue.email_to_group.group",
          text: this.$t("common.group"),
          width: 180,
          sortable: false,
        },
        {
          value: "email_to_group",
          text: "Status",
          width: 180,
          sortable: false,
        },
        {
          value: "place_in_queue",
          text: this.$t("offboardingUser.exectutionOrder"),
          width: 180,
          sortable: false,
        },
      ];
    },
    groupScopeCode() {
      return this.scopeCode(this.groupScope);
    },
    driveScopeCode() {
      return this.scopeCode(this.driveScope);
    },
    virtualScrollHeight() {
      const rangeMap = {
        2: 80,
        4: 110,
        6: 180,
        10: 250,
        Infinity: 300,
      };

      const length = this.externalMembers.length;
      for (const max in rangeMap) {
        if (length <= parseInt(max)) {
          return rangeMap[max];
        }
      }
      return rangeMap["Infinity"];
    },
  },

  methods: {
    ...mapMutations([
      "setUserOffboardSelected",
      "setShowTasksProgress",
      "setSnackBar",
    ]),
    ...mapActions([
      "getGroupsByUser",
      "checkScopes",
      "createDriveTransference",
      "loadGAPIScript",
      "startDriveTransference",
      "setMigrationStatusProgress",
      "getDriveTransferences",
      "getGroupMembers",
    ]),

    backStep() {
      this.$emit("back");
    },

    async loadGroups(user) {
      if (!this.hasScopePending) {
        await this.getGroupsByUser(user);
        this.groups = this.userGroups(user.id_google) || [];
      }
    },
    async checkGroupAndDriveScopes() {
      let responseCode = "";
      if (!this.groupScopeCode || !this.driveScopeCode) {
        const response = await this.checkScopes({
          scopes: [this.groupScope, this.driveScope],
        });
        responseCode = response.data.code;
      }

      if (
        (this.groupScopeCode || responseCode || this.driveScopeCode) !==
        "ACCEPTED_SCOPES"
      ) {
        this.hasScopePending = true;
        return;
      }
      await this.loadGAPIScript({
        scopes: [this.driveScope],
        discoveryDocs: [DISCOVERY_DOCS.DRIVE],
      });
    },

    async startTransfer() {
      this.loading = true;
      const payload = {
        group: this.group,
        user: this.offboardingUser,
        isOffboarding: true,
      };
      if (this.hasProcessRunning) return;

      const result = await this.createDriveTransference(payload);
      if (result) {
        this.errorMessageKey = result;
        this.loading = false;
        return;
      }
      await this.startDriveTransference(payload);
      const status = this.hasProcessRunning ? "in_queue" : "in_progress";
      const data = {
        email: this.offboardingUser.email,
        app_name: "drive",
        status: status,
      };
      await this.setMigrationStatusProgress(data)
        .then(() => {
          if (status === "in_progress") this.setShowTasksProgress(true);
          this.$emit("next");
        })
        .catch((err) => {
          console.log("setMigrationStatusProgress: ", err);
          this.loading = false;
          return;
        });

      this.loading = false;
    },

    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "offboarding_drive_transfer",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: "Conheça os planos no step 4 do offboarding ",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },

    async checkhasExternalMemberOnGroup() {
      this.loading = true;
      const companyDomainsSet = new Set(this.company.domains_list);
      let members = [];
      await this.getGroupMembers(this.group)
        .then(({ data }) => {
          members = data;
        })
        .finally(() => (this.loading = false));
      this.externalMembers = members.filter((email) => {
        const domain = email.split("@")[1];
        return !companyDomainsSet.has(domain);
      });
    },
  },

  watch: {
    offboardingUser(newV) {
      this.loadGroups(newV);
    },
    errorMessageKey(newV) {
      if (newV) {
        this.setSnackBar({
          message: this.$t(newV ? newV : "unknown"),
          color: "error",
          show: true,
          timeout: 15000,
        });
      }
    },
    group(newV, oldV) {
      if (newV !== oldV) this.checkhasExternalMemberOnGroup();
    },
  },

  async beforeMount() {
    if (!this.hasConectaControl) {
      this.unavailablePlan = true;
      return;
    }
    await this.checkGroupAndDriveScopes();
    if (!Object.keys(this.allDriveProcesses).length)
      await this.getDriveTransferences();
    if (this.hasProcessRunning) return;
    this.loadGroups(this.offboardingUser);
  },
};
</script>
