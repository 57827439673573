<template>
  <v-container style="max-width: 1160px" class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="pa-4 elevation-0 overflow-y-auto"
      height="calc(89vh - 80px)"
      width="100%"
    >
      <v-card-title class="pa-0 mb-2 mt-14 font-weight-bold">
        {{
          $t("offboardingUser.aplicationsOfUser", {
            user_email: offboardingUser.email,
          })
        }}
      </v-card-title>

      <UserAppsTable
        :user="offboardingUser"
        :userApps="userApps"
        :superLoading="loading"
        @setLoading="setLoading = $event"
        @syncApps="syncApps"
      />
      <v-divider v-if="unavailablePlan" class="mt-5 mb-5"></v-divider>
      <div v-if="unavailablePlan" class="px-0 mx-auto d-flex justify-center">
        <v-card
          max-width="600"
          tile
          elevation="0"
          class="d-flex flex-column align-center justify-center"
        >
          <v-card-title>
            <v-icon left color="accentSuite">mdi-crown</v-icon>
            <span> {{ $t("common.upsellTitle") }} </span>
          </v-card-title>

          <v-card-text class="font-weight-medium text-center pb-4 mb-6">
            {{ $t("driveMigration.upsellInvitation") }}
          </v-card-text>
          <v-btn
            color="accent"
            class="text-none font-weight-medium text-body-2 white--text"
            elevation="0"
            @click="goToSelfCheckout"
          >
            {{ $t("common.checkPlans") }}
          </v-btn>
        </v-card>
      </div>
    </v-card>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3 justify-space-between"
      width="100%"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="backStep"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('next')"
        :loading="loading"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserAppsTable from "@/components/user/UserAppsTable.vue";
import { appSlugs } from "@/helpers/variables/itemsNApps";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
export default {
  name: "UserDigitalInventory",
  components: {
    UserAppsTable,
  },
  data() {
    return {
      loading: false,
      userApps: {},
      unavailablePlan: false,
      appSlugs,
    };
  },
  computed: {
    ...mapGetters([
      "users",
      "userOffboardSelected",
      "currentPlan",
      "currentOffboardingStep",
    ]),
    logoSlug() {
      return (app_name) => this.appSlugs[app_name] || "application";
    },
    offboardingUser() {
      const user = JSON.parse(localStorage.getItem("offboardUser"));
      if (!this.userOffboardSelected && user) {
        this.setUserOffboardSelected(user);
      }
      return user ? user : this.userOffboardSelected;
    },
  },

  methods: {
    ...mapMutations([
      "setSnackBar",
      "setOffboardingStep",
      "setUserOffboardSelected",
    ]),
    ...mapActions(["getUserApplications", "syncUserApplications"]),

    backStep() {
      this.userApps = {};
      this.setUserOffboardSelected();
      this.$emit("back");
    },
    finish() {
      this.userApps = {};
      this.setUserOffboardSelected();
      this.$emit("goHome");
    },

    syncApps() {
      this.loading = true;
      this.syncUserApplications(this.offboardingUser.key)
        .then((response) => {
          const data = response.data;
          this.userApps = Object.fromEntries(
            data.map((app) => [
              app.name,
              { ...app, product: this.logoSlug(app.name) },
            ])
          );
        })
        .catch((err) => {
          console.error(err);
          const { data } = err.response;
          const code = data.code;
          this.setSnackBar({
            show: true,
            color: "error",
            message: errorMessages[code] || errorMessages["unknown"],
          });
        })
        .finally(() => {
          this.loading = false;
          const log = {
            event: "sync_user_apps_offboarding",
            click: "Botão de syncronizar apps do usuário no offboarding",
          };
          this.$emit("saveAnalytics", log);
        });
    },
    selectedApps(apps) {
      this.selected = apps;
    },

    setLoading(loading = false) {
      this.loading = loading;
    },

    goToSelfCheckout() {
      const log = {
        event: "go_to_self_checkout",
        click: "Botão de redirecionar para planos no step 2 offboarding",
      };
      this.$emit("saveAnalytics", log);

      this.$router.push({ name: "BillingUpgradePlan" });
    },
    async getUserConnectedAplications() {
      this.loading = true;
      await this.getUserApplications(this.offboardingUser.key)
        .then((response) => {
          const data = response.data;
          this.userApps = Object.fromEntries(
            data.map((app) => [
              app.name,
              { ...app, product: this.logoSlug(app.name) },
            ])
          );
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          if (code === "PRODUCT_IN_DISABLED_PLAN") {
            this.unavailablePlan = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    offboardingUser(newV, oldV) {
      if (newV != oldV && !!newV.email && this.currentOffboardingStep === 3) {
        this.getUserConnectedAplications();
      }
    },
  },

  async beforeMount() {
    await this.getUserConnectedAplications();
  },
};
</script>
