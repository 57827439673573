<template>
  <v-container style="max-width: 1160px" class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="pa-4 elevation-0 overflow-y-auto"
      height="calc(89vh - 80px)"
      width="100%"
    >
      <v-card-title class="pl-2 mt-14 font-weight-bold">
        {{ $t("offboardingUser.licenseAssignTitle") }}
      </v-card-title>
      <v-card-subtitle class="pl-2">
        {{ $t("offboardingUser.licenseAssignSubtitle") }}</v-card-subtitle
      >
      <Alert
        v-if="hasMigrationRunning"
        :prominent="false"
        color="warning"
        dense
      >
        {{ $t("offboardingUser.licenseAssignAlert") }}
      </Alert>
      <v-card-text class="px-0">
        <v-data-table
          :items="googleWorkspaceUserLicenses"
          :headers="headers"
          :items-per-page="itemsPerPage"
          :no-data-text="$t('offboardingUser.noLicensesAssigned')"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': itemsOptions,
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-switch
              v-model="item.status"
              :label="
                item.status ? $t('common.assigned') : $t('common.removed')
              "
              dense
              inset
            ></v-switch>
          </template>
        </v-data-table>
        <v-overlay
          :absolute="true"
          color="white"
          :value="pendingScopesOverlay"
          opacity="1"
        >
          <div
            class="d-flex flex-column align-center justify-center black--text"
          >
            <v-card-title>
              {{ $t("offboardingUser.licenseAssignTitle") }}
            </v-card-title>
            <v-card-text class="font-weight-medium text-center pb-6 mb-4">
              {{
                $t("scopesAlert.alert", {
                  functionality: $t("functionalities.licenseManager"),
                })
              }}

              <span class="d-block">
                {{
                  isAdmin
                    ? $t("scopesAlert.requestToAcceptScopes")
                    : $t("scopesAlert.requestAdminToAcceptScopes")
                }}
              </span>
            </v-card-text>

            <div>
              <v-btn
                v-if="isAdmin"
                max-width="200"
                :block="false"
                color="accent"
                class="text-none font-weight-medium text-body-2 white--text"
                elevation="0"
                :href="MARKETPLACE_APP_URL"
                target="_blank"
                @click="openAcceptScopesLink"
              >
                {{ $t("action.acceptScopes") }}
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
              <v-btn
                v-if="showReloadBtn"
                max-width="200"
                :block="false"
                color="accent"
                outlined
                class="text-none font-weight-medium text-body-2 white--text ml-4"
                elevation="0"
                :loading="loading"
                @click="fetchAssignedLicenses"
              >
                {{ $t("action.reloadUserInfo") }}
                <v-icon right>mdi-reload</v-icon>
              </v-btn>
            </div>
          </div>
        </v-overlay>
      </v-card-text>
    </v-card>

    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3 justify-space-between"
      width="100%"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="backStep"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="removeLicenses"
        :loading="loading"
      >
        {{ $t("action.finish") }}
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { paging } from "@/helpers/variables/tablesDefault";
import { snackbarMessages } from "@/helpers/variables/snackbarMessages";
import { MARKETPLACE_APP_URL } from "@/helpers/variables/scopes";

export default {
  name: "LicenseAssignment",
  components: {},
  data() {
    return {
      loading: false,
      user: {},
      googleWorkspaceUserLicenses: [],
      licensesPendingRemoval: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      hasMigrationRunning: false,
      pendingScopesOverlay: false,
      MARKETPLACE_APP_URL,
      showReloadBtn: false,
    };
  },
  computed: {
    ...mapGetters([
      "userOffboardSelected",
      "userLicenses",
      "isAdmin",
      "currentOffboardingStep",
    ]),

    headers() {
      return [
        {
          value: "skuName",
          text: this.$t("common.licences"),
          width: 180,
          sortable: false,
        },
        {
          value: "status",
          text: "Status",
          width: 180,
          sortable: false,
        },
      ];
    },

    offboardingUser() {
      const user = JSON.parse(localStorage.getItem("offboardUser"));
      if (!this.userOffboardSelected && user) {
        this.setUserOffboardSelected(user);
      }
      return user || this.userOffboardSelected;
    },
  },

  methods: {
    ...mapActions([
      "getAssignedLicenses",
      "removeGoogleWorkspaceLicenses",
      "getMigrationStatusProgress",
    ]),

    ...mapMutations([
      "setUserOffboardSelected",
      "setGoogleWorkspaceLicensesAssigned",
      "setSnackBar",
      "setInitiatedMigration",
    ]),

    openAcceptScopesLink() {
      this.showReloadBtn = true;
    },

    backStep() {
      this.$emit("back");
    },

    getRemovedLicenses() {
      return this.googleWorkspaceUserLicenses.reduce((acc, license) => {
        if (license.status) {
          return acc;
        }

        acc.push({
          product_id: license["productId"],
          self_link: license["selfLink"],
          sku_id: license["skuId"],
          sku_name: license["skuName"],
        });

        return acc;
      }, []);
    },

    removeLicenses() {
      this.loading = true;
      const removedLicenses = this.getRemovedLicenses();

      if (!removedLicenses.length && !this.licensesPendingRemoval.length) {
        this.loading = false;
        this.goToNextStep();
        return;
      }

      const payload = {
        email: this.offboardingUser.email,
        assigned_licenses: removedLicenses,
      };

      this.removeGoogleWorkspaceLicenses(payload)
        .then(({ data }) => {
          this.googleWorkspaceUserLicenses =
            this.googleWorkspaceUserLicenses.filter(
              (license) => license.status
            );

          if (data.code == "SCHEDULE_LICENSE_DELETION") {
            this.setInitiatedMigration(true);
          }

          this.setSnackBar({
            show: true,
            message:
              snackbarMessages[data.code] || snackbarMessages["saves_changes"],
          });

          this.goToNextStep();
        })
        .catch((error) => {
          const { data } = error.response;
          this.setSnackBar({
            show: true,
            message: snackbarMessages[data.code] || snackbarMessages["unknown"],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    goToNextStep() {
      this.googleWorkspaceUserLicenses = [];
      this.$emit("next");
    },

    handleFetchAssignedLicensesError({ response }) {
      const shallAcceptScopes = [
        "INVALID_GOOGLE_ACCESS_TOKEN",
        "INVALID_ADMIN",
      ];

      if (shallAcceptScopes.includes(response.data.code)) {
        this.pendingScopesOverlay = true;
        this.showReloadBtn = false;
      }
    },

    async fetchAssignedLicenses() {
      try {
        this.loading = true;

        const { data } = await this.getAssignedLicenses(
          this.offboardingUser.email
        );

        this.googleWorkspaceUserLicenses = data.map((item) => ({
          ...item,
          status: true,
        }));

        this.showReloadBtn = false;
        this.pendingScopesOverlay = false;
      } catch (error) {
        this.handleFetchAssignedLicensesError(error);
      } finally {
        this.loading = false;
      }
    },

    async updateMigrationStatusProgress() {
      try {
        this.loading = true;
        const { data } = await this.getMigrationStatusProgress(
          this.offboardingUser.email
        );

        const { migration_status, migrations_are_running } = data;

        if (migration_status) {
          this.licensesPendingRemoval = migration_status.workspace_licenses;
        }

        this.hasMigrationRunning = migrations_are_running;

        this.googleWorkspaceUserLicenses = this.googleWorkspaceUserLicenses.map(
          (licenseAssigned) => {
            const licensePending = this.licensesPendingRemoval.find(
              (license) => license.sku_id === licenseAssigned.skuId
            );

            this.$set(licenseAssigned, "status", !licensePending);

            return licenseAssigned;
          }
        );
      } catch (error) {
        console.error("Error fetching migration status progress:", error);
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    async offboardingUser(newV, oldV) {
      if (newV != oldV && newV.email && this.currentOffboardingStep <= 7) {
        await this.fetchAssignedLicenses();

        if (!this.pendingScopesOverlay) {
          await this.updateMigrationStatusProgress();
        }
      }
    },
  },

  async beforeMount() {
    this.loading = true;

    await this.fetchAssignedLicenses();

    if (!this.pendingScopesOverlay) {
      await this.updateMigrationStatusProgress();
    }
  },
};
</script>
