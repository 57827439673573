<template>
  <v-container class="d-flex justify-center align-center">
    <v-card
      class="my-3 rounded-lg overflow-y-auto d-flex justify-center align-center"
      tile
      height="calc(100vh - 10px)"
      flat
      max-width="1200"
    >
      <v-row justify="center" align="center" class="mb-3">
        <v-col
          :class="$vuetify.breakpoint.lg ? 'pt-10' : ''"
          cols="6"
          sm="6"
          md="6"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-card flat class="d-flex flex-column justify-center align-center">
            <v-img
              width="550"
              max-width="550px"
              :src="photo"
              alt="Offboarding photo"
              class="mb-0"
            ></v-img>
          </v-card>
        </v-col>
        <v-col
          cols="6"
          sm="12"
          md="6"
          :class="$vuetify.breakpoint.lg ? 'pt-10' : ''"
        >
          <v-card
            flat
            class="d-flex flex-column justify-center align-center mr-5"
          >
            <v-card-title
              class="primary--text text-h6 text-sm-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
              style="max-width: 500px"
            >
              {{ $t("offboardingUser.welcomeOffboardingTitle") }}
            </v-card-title>

            <v-subheader
              class="text-none text-body-1 text-sm-h6 font-weight-medium text-center"
            >
              {{ $t("offboardingUser.welcomeOffboardingSubtitle") }}
            </v-subheader>

            <v-list class="px-0">
              <v-list-item
                v-for="({ title }, index) in getStartedList"
                :key="index"
              >
                <v-list-item-action class="mr-4">
                  <v-icon
                    v-text="'mdi-check-circle'"
                    size="26"
                    color="success"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-wrap break-word normal-line-height text-body-1 secondary--text"
                  >
                    {{ title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>

        <v-col cols="12" class="d-flex">
          <v-btn
            right
            height="58"
            color="accent"
            class="mx-auto text-none text-h6 font-weight-bold px-8"
            @click="$emit('started')"
          >
            {{ $t("common.start") }}
            <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: "StartedOffboarding",

  data() {
    return {
      photo: require("@/assets/images/offboarding-user.svg"),
    };
  },

  computed: {
    getStartedList() {
      return [
        {
          title: this.$t("offboardingUser.totalStepsLabel"),
        },
        {
          title: this.$t("offboardingUser.progressAutomaticSaveLabel"),
        },
        {
          title: this.$t("offboardingUser.suportHelperLabel"),
        },
      ];
    },
  },
};
</script>
