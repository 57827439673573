<template>
  <v-container style="max-width: 1160px" class="d-flex flex-column fill-height">
    <v-card
      tile
      flat
      class="pa-4 elevation-0 overflow-y-auto"
      height="calc(89vh - 80px)"
      width="100%"
    >
      <Alert
        class="mt-2"
        dense
        color="warning"
        v-if="!offboardingUser.is_gmail_enabled"
      >
        {{ $t("common.inactiveUserInWorkspaceAlert") }}
      </Alert>

      <Alert
        v-if="hasTaskRunning && offboardingUser.is_gmail_enabled"
        :prominent="false"
        color="info"
        dense
      >
        {{
          $t("offboardingUser.hasTaskRunningAlert", {
            email: offboardingUser.email,
          })
        }}
      </Alert>
      <v-card-title class="pl-2 mt-6 font-weight-bold">
        {{ $t("offboardingUser.transferEmailTitle") }}
      </v-card-title>
      <v-card-subtitle class="pl-2">{{
        $t("offboardingUser.transferEmailSubtitle")
      }}</v-card-subtitle>

      <MigrateEmail
        :dialogMessage="$t('emailMigration.dialog.transferBackground')"
        isPanelOpened
        :user="offboardingUser"
        trackMigrationStatus
        @migrationInQueue="setMigrationsInQueue"
      />

      <div v-if="showMigrationsInQueue">
        <v-card-title class="pl-2 mt-6 font-weight-bold">
          {{ $t("offboardingUser.migrationsInQTitle") }}
        </v-card-title>
        <v-card-subtitle class="pl-2">
          {{ $t("offboardingUser.migrationsInQSubtitle") }}
        </v-card-subtitle>

        <v-data-table
          :items="migrationsInQueue"
          :headers="headers"
          :items-per-page="itemsPerPage"
          :no-data-text="$t('offboardingUser.noLicensesAssigned')"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': itemsOptions,
          }"
        >
          <template v-slot:[`item.email_to_group`]="{ item }">
            {{ $t(`offboardingUser.${item.email_to_group}`) }}
          </template>

          <template v-slot:[`item.place_in_queue`]="{ item }">
            <span
              :class="`font-weight-bold ${
                item.place_in_queue == 'next' ? 'green--text' : 'warning--text'
              }`"
            >
              {{ $t(`common.${item.place_in_queue}`) }}
            </span>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3 justify-space-between"
      width="100%"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="backStep"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('next')"
        :loading="loading"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import MigrateEmail from "@/components/user/MigrateEmail";
import { paging } from "@/helpers/variables/tablesDefault";

export default {
  name: "TransferEmail",
  components: {
    MigrateEmail,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      migrationsInQueue: [],
      showMigrationsInQueue: false,
      hasTaskRunning: false,
    };
  },
  computed: {
    ...mapGetters(["userOffboardSelected"]),
    offboardingUser() {
      const user = JSON.parse(localStorage.getItem("offboardUser"));
      if (!this.userOffboardSelected && user) {
        this.setUserOffboardSelected(user);
      }
      return user || this.userOffboardSelected;
    },

    headers() {
      return [
        {
          value: "email",
          text: this.$t("common.email"),
          width: 180,
          sortable: false,
        },
        {
          value: "migrations_in_queue.email_to_group.group",
          text: this.$t("common.group"),
          width: 180,
          sortable: false,
        },
        {
          value: "email_to_group",
          text: "Status",
          width: 180,
          sortable: false,
        },
        {
          value: "place_in_queue",
          text: this.$t("offboardingUser.exectutionOrder"),
          width: 180,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapMutations(["setUserOffboardSelected"]),
    ...mapActions(["checkRunningTasks"]),

    backStep() {
      this.$emit("back");
    },

    setMigrationsInQueue(allMigrationsInQueue) {
      this.migrationsInQueue = allMigrationsInQueue;
      this.showMigrationsInQueue = true;
    },
  },

  beforeMount() {
    this.loading = true;
    this.checkRunningTasks()
      .then(({ data }) => {
        this.hasTaskRunning = data.has_task_running;
      })
      .catch(() => {})
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
